import classNames from '@/utils/className';

export interface LoadingLogoProps {
  hasTransparentBackground?: boolean;
  isLoading?: boolean;
  hasGrayIcon?: boolean;
}

export default function LoadingLogo({ hasTransparentBackground = false, isLoading = false, hasGrayIcon = false }: LoadingLogoProps) {
  const variants = {
    LoaderLogoContainer: {
      hasTransparentBackground: {
        true: 'bg-transparent',
        false: 'bg-mglGray50',
      },
      isLoading: {
        true: 'mb-8',
        false: 'hidden',
      },
      hasGrayIcon: {
        true: '[&>div>div>svg]:fill-[#999] [&>div>div>svg]:stroke-[#999]',
        false: '',
      },
    },
    CtLogo: {
      hasTransparentBackground: {
        true: 'fill-mglGray50',
        false: '',
      },
      isLoading: {
        true: 'visible',
        false: 'invisible',
      },
    },
    CtCircular: {
      hasTransparentBackground: {
        true: 'stroke-mglGray50',
        false: '',
      },
      isLoading: {
        true: 'visible',
        false: 'invisible',
      },
    },
  } as const;

  return (
    <div
      className={classNames(
        'grid h-24 pb-[50px] [grid-column-end:1] [grid-column-start:1] phone:[grid-column-end:3] desktop:pb-0 desktop:[grid-column-end:4]',
        variants.LoaderLogoContainer.hasGrayIcon[hasGrayIcon.toString()],
        variants.LoaderLogoContainer.hasTransparentBackground[hasTransparentBackground.toString()],
        variants.LoaderLogoContainer.isLoading[isLoading.toString()]
      )}>
      <div className="flex h-auto w-full items-center justify-center p-2">
        <div className="relative mx-auto my-0 block h-[50px] w-[50px]">
          <svg
            className={classNames(
              'absolute left-[calc(50%-12px)] top-[calc(50%-9px)] h-[18px] w-[25px] fill-[#999]',
              variants.CtLogo.hasTransparentBackground[hasTransparentBackground.toString()],
              variants.CtLogo.isLoading[isLoading.toString()]
            )}
            viewBox="0 0 26.64 18.84">
            <polygon
              className="inner"
              points="0 0 0 18.84 17.22 18.84 12.51 14.13 4.71 14.13 4.71 4.71 17.22 4.71 17.22 18.84 21.93 18.84 21.93 4.71 26.64 4.71 26.64 0 0 0"
            />
          </svg>
          <svg
            className={classNames(
              'absolute bottom-0 left-0 right-0 top-0 m-auto h-[50px] w-[50px] animate-rotate stroke-[#999]',
              variants.CtCircular.hasTransparentBackground[hasTransparentBackground.toString()],
              variants.CtCircular.isLoading[isLoading.toString()]
            )}
            viewBox="0 0 50 50"
          />
          <span className="absolute inset-0 box-border h-[50px] w-[50px] animate-pulse-loading rounded-half border-[4px] border-solid border-[#999]" />
        </div>
      </div>
    </div>
  );
}
